import React, {Component} from "react"
import {connect} from "react-redux"
import {removeFatalError} from "../../modules/FatalErrorModule"
import Error from "../../components/Error/Error"
import ScreenSpinner from "../../components/ScreenSpinner/ScreenSpinner"
import StartScreen from "../../components/StartScreen/StartScreen"
import CounterScreen from "../../components/CounterScreen/CounterScreen"
import FinalScreen from "../../components/FinalScreen/FinalScreen"
import {CLICK_TIME, FREEZE_TIME} from "../../modules/ClickModule";

class DesktopContainer extends Component {

	state = {
		timeNow: Math.round((new Date())/1000)
	};

	constructor(props) {
		super(props);

		this.updateTime = this.updateTime.bind(this)
	}

	updateTime() {
		this.setState({ timeNow: Math.round((new Date())/1000)})
	}

	renderLoading() {
		return <ScreenSpinner/>
	}

	renderPage() {
		const timeDiff = CLICK_TIME - this.state.timeNow;

		if (timeDiff <= 0) {
			return <FinalScreen/>
		} else if (this.props.userStatus === 'member' || timeDiff <= FREEZE_TIME) {
			return <CounterScreen updateTime={this.updateTime} />
		} else {
			return <StartScreen isMobile={false}/>
		}
	}

	render() {
		let {fatal, removeFatalError, loading} = this.props;
		if (fatal) return <Error error={fatal} onClose={() => removeFatalError()}/>;

		return loading ? this.renderLoading() : this.renderPage()
	}
}


function mapStateToProps(state) {
	return {
		fatal: state.FatalErrorModule,
		loading: state.ClickModule.loading,
		userStatus: state.ClickModule.userStatus
	}
}

export default connect(mapStateToProps, {removeFatalError})(DesktopContainer)
