import React, {Component} from "react"
import "./ScreenSpinner.css"

export default class ScreenSpinner extends Component {

	render() {
		return (
			<div className="ScreenSpinner">
				<div className="ScreenSpinner__inner">
					<div className="ScreenSpinner__spinner"/>
				</div>
			</div>
		)
	}
}
