import React, {Component} from "react"
import {connect} from "react-redux"
import {removeFatalError} from "../../modules/FatalErrorModule"
import L from "../../lang/L"
import Error from "../../components/Error/Error"
import ScreenSpinner from "../../components/ScreenSpinner/ScreenSpinner"
import StartScreen from "../../components/StartScreen/StartScreen"
import CounterScreen from "../../components/CounterScreen/CounterScreen"
import FinalScreen from "../../components/FinalScreen/FinalScreen"
import {isDeviceSupported} from "../../tools/helpers"
import {CLICK_TIME, FREEZE_TIME} from "../../modules/ClickModule";

class MobileContainer extends Component {

	static deviceWidth = 0;
	static deviceHeight = 0;

	state = {
		timeNow: Math.round((new Date())/1000)
	};

	constructor(props) {
		super(props);

		if (MobileContainer.deviceWidth === 0) {
			MobileContainer.deviceWidth = window.innerWidth
		}

		if (MobileContainer.deviceHeight === 0 && window.innerHeight) {
			MobileContainer.deviceHeight = window.innerHeight - this.getPanelHeaderHeight()
		}

		if (MobileContainer.deviceWidth < 10) {
			this.recheckDimensions()
		}

		if ('onorientationchange' in window) {
			window.addEventListener("orientationchange", () => {
				MobileContainer.deviceHeight = window.innerWidth - this.getPanelHeaderHeight();
				MobileContainer.deviceWidth = window.innerHeight;
				this.setState({time: Date.now()})
			}, false)
		}

		this.updateTime = this.updateTime.bind(this)
	}

	recheckDimensions() {
		if (MobileContainer.deviceWidth < 10) {
			setTimeout(() => {
				try {
					MobileContainer.deviceHeight = window.innerHeight - this.getPanelHeaderHeight();
					MobileContainer.deviceWidth = document.documentElement.offsetWidth
				} catch (e) {
					MobileContainer.deviceWidth = window.innerWidth
				}
				this.setState({time: Date.now()});
				this.recheckDimensions()
			}, 100)
		}
	}

	getPanelHeaderHeight() {
		return ~this.props.platform.indexOf('android') ? 56 : 44
	}

	updateTime() {
		this.setState({ timeNow: Math.round((new Date())/1000)})
	}

	renderDeviceNotSupportedScreen() {
		return (
		<div className="NotSupported" style={{
				width: MobileContainer.deviceWidth,
				height: MobileContainer.deviceHeight,
			}}>
				<div className="NotSupported__inner">
					<div className="NotSupported__img"/>
					<div className="NotSupported__text">{L.t('not_supported')}</div>
				</div>
			</div>
		)
	}

	renderPage() {
		const timeDiff = CLICK_TIME - this.state.timeNow;

		if (timeDiff <= 0) {
			return <FinalScreen/>
		} else if (this.props.userStatus === 'member' || timeDiff <= FREEZE_TIME) {
			return <CounterScreen updateTime={this.updateTime} />
		} else {
			return <StartScreen isMobile={true}/>
		}
	}

	render() {
		let {fatal, removeFatalError, loading} = this.props;
		if (fatal) return <Error error={fatal} onClose={() => removeFatalError()}/>;

		if (!isDeviceSupported()) return this.renderDeviceNotSupportedScreen();

		return loading ? <ScreenSpinner/> : this.renderPage()
	}
}

function mapStateToProps(state) {
	return {
		fatal: state.FatalErrorModule,
		loading: state.ClickModule.loading,
		userStatus: state.ClickModule.userStatus
	}
}

export default connect(mapStateToProps, { removeFatalError })(MobileContainer)
