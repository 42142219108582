import React, {Component} from "react"
import {connect} from "react-redux"
import "./Button.css"

class Button extends Component {

	gesture = {};

	state = {
		active: false
	};

	constructor(props) {
		super(props);

		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this)
	}

	coordX = e => e.clientX || (e.touches && e.touches[0].clientX);

	coordY = e => e.clientY || (e.touches && e.touches[0].clientY);

	onTouchStart(e) {
		this.gesture = {
			startX: this.coordX(e),
			startY: this.coordY(e)
		};
		this.setState({active: true})
	}

	onTouchMove(e) {
		const shiftXAbs = Math.abs(this.coordX(e) - this.gesture.startX),
			shiftYAbs = Math.abs(this.coordY(e) - this.gesture.startY);

		if (shiftXAbs > 20 || shiftYAbs > 20) this.setState({active: false})
	}

	onTouchEnd() {
		this.gesture = {};
		this.setState({active: false})
	}

	render() {
		const {component, type, size, onClick, className, children, ...restProps} = this.props,
			{active} = this.state,
			classNameFull = `${className ? className : ''} Button${type ? ` Button--${type}` : ''}${size ? ` Button--${size}` : ''}${active ? ' Button--active' : ''}`,
			Component = component ? component : this.props.href ? "a" : 'button';

		return (
			<Component className={classNameFull}
					 onClick={onClick}
					 onTouchStart={this.onTouchStart}
					 onTouchMove={this.onTouchMove}
					 onTouchEnd={this.onTouchEnd}
					 {...restProps}>
				{children}
			</Component>
		)
	}
}

function map(state) {
	return {}
}

export default connect(map, {})(Button)
