import React, {Component} from "react"
import {connect} from "react-redux"
import "./StartScreen.css"
import L from "../../lang/L"
import TermsScreen from "../TermsScreen/TermsScreen"
import {nToBr} from "../../tools/helpers";
import Button from "../Button/Button"
import {setUserAlreadyRefused} from "../../modules/ClickModule";

class StartScreen extends Component {

	state = {
		termsOpened: false,
		termsVisible: false
	};

	constructor(props) {
		super(props);

		this.openTerms = this.openTerms.bind(this);
		this.closeTerms = this.closeTerms.bind(this);
	}

	openTerms() {
		this.setState({ termsOpened: true });
		setTimeout(() => { this.setState({ termsVisible: true }) }, 70);
	}

	closeTerms() {
		this.props.setUserAlreadyRefused(true);
		this.setState({ termsVisible: false });
		setTimeout(() => { this.setState({ termsOpened: false }) }, 70);
	}

	render() {
		const {alreadyRefused} = this.props;

		return (
			<div className="StartScreen__wrap">
				<div className={`StartScreen${this.state.termsOpened ? ' StartScreen__terms_opened' : '' }`}>
					<div className="StartScreen__copyright">©MARVEL</div>
					<div className="StartScreen__inner">
						<div className={`StartScreen__content${alreadyRefused ? '' : ' StartScreen__content_first'}`}>
							<div className="StartScreen__title">
								{this.props.isMobile ? nToBr(L.t('start_screen_title_mobile')) : L.t('start_screen_title')}
							</div>
							{alreadyRefused ?
								<div className="StartScreen__info">
									{nToBr(L.t('start_screen_text_refused'))}
								</div> :
								<div className="StartScreen__info">
									<div className="StartScreen__text">{nToBr(L.t('start_screen_text_1'))}</div>
									<div className="StartScreen__text">{nToBr(L.t('start_screen_text_2'))}</div>
									<div className="StartScreen__text">{nToBr(L.t('start_screen_text_3'))}</div>
								</div>
							}
							<div className="StartScreen__actions">
								<Button type="color"
												size={this.props.isMobile ? 'large' : ''}
												className="StartScreen__button"
												onClick={this.openTerms}>{L.t('button_participate')}</Button>
							</div>
						</div>
					</div>
				</div>
				{this.state.termsOpened &&
					<TermsScreen closeTerms={this.closeTerms}
											 termsVisible={this.state.termsVisible} />
				}
			</div>
		)
	}
}

function map(state) {
	return {
		alreadyRefused: state.ClickModule.userAlreadyRefused
	}
}

export default connect(map, {setUserAlreadyRefused})(StartScreen)
