import React, {Component} from "react"
import {connect} from "react-redux"
import Button from "../Button/Button"
import StorySharing from "../StorySharing/StorySharing"
import "./SharingMenu.css"
import L from "../../lang/L"
import {apiRequest, vkConnectRequest} from "../../tools/VkConnectRequest";
import VkSdk from "@happysanta/vk-apps-sdk";
import * as VkConnect from "@vkontakte/vkui-connect/index"

class SharingMenu extends Component {

	state = {};

	constructor(props) {
		super(props);

		this.sharePost = this.sharePost.bind(this);
		this.shareStory = this.shareStory.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.closeStorySharingAccept = this.closeStorySharingAccept.bind(this);
	}

	sharePost() {
		const {closeMenu, status, userInfo} = this.props,
			sex = userInfo.sex === 1 ? 'f' : userInfo.sex === 2 ? 'm' : 'un';

		closeMenu();
		let snippetImage = window.location.origin + window.location.pathname;

		if (status === 'member') {
			snippetImage = snippetImage + require(`./img/snippet_member.png`);
		} else {
			snippetImage = snippetImage + require(`./img/snippet_${status}_${sex}.png`)
		}
		vkConnectRequest('VKWebAppShowWallPostBox',
			{
				message: L.t(`sharing_text_${status}_${sex}`),
				attachments: `https://vk.com/app${VkSdk.getStartParams().appId}`,
				link_image: snippetImage,
				link_title: L.t('app_title'),
				link_button: 'open_url'
			},
			'VKWebAppShowWallPostBox', 'VKWebAppShowWallPostBoxFailed'
		)
			.then(() => { VkConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#25274A"}) })
			.catch(() => { VkConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#25274A"}) } )
	}

	shareStory() {
		vkConnectRequest('VKWebAppGetAuthToken',
			{"app_id": VkSdk.getStartParams().appId, "scope": 'stories'},
			'VKWebAppAccessTokenReceived', 'VKWebAppAccessTokenFailed'
		)
			.then(async (res) => {
				if (~res.scope.indexOf('stories')) {
					const access_token = res.access_token;
					const storyUploadServer =
						await apiRequest('stories.getPhotoUploadServer', {
							add_to_news: 1, link_text: "learn_more", link_url: `https://vk.com/app${VkSdk.getStartParams().appId}`
						}, access_token);

					this.setState({
						storySharingAcceptIsVisible: true,
						sharingMenuHidden: true,
						storyUploadServer
					});
				}
			})
			.catch((err) => {console.log(err)})
	}

	closeMenu(event) {
		if (~event.target.className.indexOf('SharingMenu_wrap') || ~event.target.className.indexOf('SharingMenu__cancel')) {
			this.props.closeMenu()
		}
	}

	closeStorySharingAccept() {
		this.setState({
			storySharingAcceptIsVisible: false
		});
	}

	renderMenuWrap(isMobile) {
		const {isVisible, closeMenu, hideMenu} = this.props,
			{storySharingAcceptIsVisible, storyUploadServer} = this.state,
			visibleClass = isVisible ? ' SharingMenu__visible' : '',
			storySharingClass = storySharingAcceptIsVisible ? ' SharingMenu__story_accept' : '';

		return (
			<div className={`SharingMenu SharingMenu_wrap${visibleClass}${storySharingClass}`}
					 onClick={this.closeMenu}>
				{isMobile && this.renderActions(true)}
				<StorySharing closeStorySharingAccept={this.closeStorySharingAccept}
											closeMenu={closeMenu}
											hideMenu={hideMenu}
											userStatus={this.props.status}
											storyUploadServer={storyUploadServer} />
			</div>
		)
	}

	renderActions(isMobile) {
		const isAndroid = !!~VkSdk.getStartParams().platform.indexOf('android');

		return (
			<div className={`SharingMenu__actions${this.state.sharingMenuHidden ? ' SharingMenu__actions_hidden' : ''}`}>
				<div className="SharingMenu__list">
					<Button className="SharingMenu__item"
									type="link"
									onClick={this.sharePost}>{L.t(isMobile ? 'button_post' : 'button_post_desktop')}</Button>
					<Button className="SharingMenu__item"
									type="link"
									onClick={this.shareStory}>{L.t('button_story')}</Button>
				</div>
				{isMobile && !isAndroid &&
					<Button type="link" className="SharingMenu__cancel">
						{L.t('button_cancel')}
					</Button>
				}
			</div>
		)
	}

	render() {
		const isMobile = VkSdk.getStartParams().isMobile();

		return isMobile ?
			this.renderMenuWrap(true) :
			<div className="SharingMenuDesktop">
				{this.renderActions(false)}
				{this.renderMenuWrap(false)}
			</div>
	}
}

function map(state) {
	return {
		userInfo: state.ClickModule.userInfo
	}
}

export default connect(map, {})(SharingMenu)
