import React, {Component} from 'react'
import {connect} from "react-redux"
import './BottomPopup.css'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class BottomPopup extends Component {

	state = {
		rendered: false
	};

	timer = null;

	componentDidMount() {
		this.timer = setTimeout(() => {
			this.setState({rendered: true});
			this.timer = null
		}, 200)
	}

	onClose() {
		if (this.timer) return;

		this.setState({rendered: false});
		this.timer = setTimeout(() => {
			this.props.onClose();
			this.timer = null
		}, 250)
	}

	renderPopupBody() {
		let {showCross, style, children, footer} = this.props;

		return (
			<div style={style || {}} className="BottomPopup">
				<div className="BottomPopup__header">
					{showCross &&
						<div className="BottomPopup__close" onClick={() => this.onClose()}/>
					}
				</div>
				{children}
				{!!footer &&
					<div className="BottomPopup__footer">{footer}</div>
				}
			</div>
		)
	}

	render() {
		return (
			<ReactCSSTransitionGroup transitionName="PopupAnimation"
															 className="PopupAnimationWrapper"
															 transitionEnterTimeout={200}
															 transitionLeaveTimeout={200}>
				{this.state.rendered && this.renderPopupBody()}
			</ReactCSSTransitionGroup>
		)
	}

}

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps, {})(BottomPopup)
