import React, {Component} from "react"
import {connect} from "react-redux"
import VkSdk from "@happysanta/vk-apps-sdk"
import L from "../../lang/L"
import "./StorySharing.css"
import Button from "../Button/Button"
import WaitDots from "../WaitDots/WaitDots"
import {shareStoryUpload} from "../../modules/ClickModule";

class StorySharing extends Component {

	state = {
		storyIsUploading: false,
		storyIsUploaded: false
	};

	componentDidUpdate() {
		if (!this.state.storyIsUploading && this.props.storyUploading) {
			this.setState({storyIsUploading: true})
		}
		if (this.state.storyIsUploading && !this.props.storyUploading) {
			this.setState({storyIsUploading: false});
			this.props.closeStorySharingAccept();
			this.props.hideMenu();
			setTimeout(() => {this.setState({storyIsUploaded: true})}, 310);
			setTimeout(() => {
				this.setState({storyIsUploaded: false});
				this.props.closeMenu();
			}, 2310)
		}
	}

	render() {
		const {userInfo, userStatus, shareStoryUpload, storyUploading, storyUploadServer, closeStorySharingAccept} = this.props;
		const isMobile = VkSdk.getStartParams().isMobile();
		let storyImage = window.location.origin + window.location.pathname;

		if (userStatus === 'member') {
			storyImage = storyImage + require(`../SharingMenu/img/story_member.png`);
		} else {
			const sex = userInfo.sex === 1 ? 'f' : userInfo.sex === 2 ? 'm' : 'un';
			storyImage = storyImage + require(`../SharingMenu/img/story_${userStatus}_${sex}.png`)
		}

		return (
			<div className="StorySharing__wrap">
				<div className="StorySharing">
					<div className="StorySharing__inner">
						<div className="StorySharing__story" style={{backgroundImage: `url(${storyImage})`}}/>
						<div className="StorySharing__actions">
							<Button onClick={() => shareStoryUpload(storyUploadServer.upload_url, userStatus, userInfo.sex)}
											className="StorySharing__button"
											type="color"
											size={isMobile ? "large" : ""}>
								{storyUploading ?
									<WaitDots /> :
									isMobile && window.innerWidth > 320 ? L.t('button_story_publish') : L.t('button_story_publish_small')
								}
							</Button>
							<Button className="StorySharing__button"
											onClick={closeStorySharingAccept}
											type="transparent"
											size={isMobile ? "large" : ""}>{L.t('button_cancel')}</Button>
						</div>
					</div>
				</div>
				{this.state.storyIsUploaded &&
				<div className="StorySharing__success">
					<div className="StorySharing__success_inner">
						<div className="StorySharing__success_text">
							{L.t('story_sharing_success')}
						</div>
					</div>
				</div>
				}
			</div>
		)
	}
}

function map(state) {
	return {
		storyUploading: state.ClickModule.storyUploading,
		userInfo: state.ClickModule.userInfo
	}
}

export default connect(map, {shareStoryUpload})(StorySharing)
