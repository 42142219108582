import React, {Component} from "react"
import {connect} from "react-redux"
import "./TermsScreen.css"
import L from "../../lang/L"
import {nToBr} from "../../tools/helpers";
import Button from "../Button/Button"
import WaitDots from "../WaitDots/WaitDots"
import {changeStatusInGame, setUserAlreadyRefused} from "../../modules/ClickModule";

class TermsScreen extends Component {

	constructor(props) {
		super(props);

		this.acceptGame = this.acceptGame.bind(this)
	}

	acceptGame() {
		this.props.setUserAlreadyRefused(false);
		this.props.changeStatusInGame('member')
	}

	render() {
		return (
			<div className={`TermsScreen${this.props.termsVisible ? ' TermsScreen_visible' : ''}`}>
				<div className="TermsScreen__inner">
					<div className="TermsScreen__title">{L.t('terms_screen_title')}</div>
					<div className="TermsScreen__info">
						<div className="TermsScreen__text">{nToBr(L.t('terms_screen_text_1'))}</div>
						<div className="TermsScreen__text">{nToBr(L.t('terms_screen_text_2'))}</div>
					</div>
					<div className="TermsScreen__actions">
						<Button className="TermsScreen__button"
										type="color"
										onClick={this.acceptGame}>
							{this.props.userStatusUpdating ? <WaitDots/> : L.t('button_accept')}
						</Button>
						<Button className="TermsScreen__button"
										type="transparent"
										onClick={this.props.closeTerms}>{L.t('button_refuse_small')}</Button>
					</div>
				</div>
			</div>
		)
	}
}

function map(state) {
	return {
		userStatusUpdating: state.ClickModule.userStatusUpdating
	}
}

export default connect(map, {changeStatusInGame, setUserAlreadyRefused})(TermsScreen)
