import connect from "@vkontakte/vkui-connect"

const API_VERSION = '5.92';

export function vkConnectRequest (eventName, eventParams, successEvent, failEvent) {
	return new Promise( (resolve) => {
		vkConnectHandler(eventName, eventParams, successEvent, failEvent)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				resolve(err)
			})
	})
}

export function apiRequest(method, params = {}, accessToken = '', raw = false, retry = 10) {
	let request_id = getNewRequestId();

	if (params['v'] === undefined) params['v'] = API_VERSION;
	params['access_token'] = accessToken;

	return new Promise( async (resolve,reject) => {
		let apiResponse = {};

		const apiTimeout = setTimeout(() => {
			let dataIsEmpty = apiResponse === undefined;
			if (!dataIsEmpty && Object.keys(apiResponse).length === 0) dataIsEmpty = true;

			if (dataIsEmpty) resolve({})
		},30000);

		try {
			apiResponse = await vkConnectHandler('VKWebAppCallAPIMethod', {
				method, params, request_id
			}, 'VKWebAppCallAPIMethodResult', 'VKWebAppCallAPIMethodFailed');

			clearTimeout(apiTimeout);

			raw ? resolve(apiResponse) : resolve(apiResponse.response)
		} catch(err) {
			if (retry > 0) {
				if (err !== undefined && err.error_type === 'client_error' && (err.error_data.error_code === 6 || err.error_data.error_reason.error_code === 6)) {
					setTimeout( () => {
						apiRequest(method, params, accessToken, raw, retry-1).then(resolve).catch(reject)
					}, 1100 )
				} else {
					reject(err)
				}
			} else {
				reject(err)
			}
		}
	})
}

export function vkConnectHandler(eventName, eventParams, successEvent, failEvent) {

	return new Promise( (resolve,reject) => {
		const isApiRequest = !!eventParams.request_id;

		let clb = function callback(e) {
			let vkEvent = e.detail;
			if (!vkEvent) {
				console.error('invalid event', e);
				return;
			}

			let type = vkEvent['type'],
				data = vkEvent['data'],
				found = false;

			if (successEvent === type) {
				if (isApiRequest && data['request_id'] !== eventParams.request_id) return false;
				resolve(data);

				found = true;
			} else if (failEvent === type) {
				reject(data);

				found = true;
			}

			if (found) connect.unsubscribe(clb);
		};

		connect.subscribe(clb);
		connect.send(eventName, eventParams);
	})
}

function getNewRequestId() {
	return (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString();
}
